@font-face {
    font-family: papyrus;
    src: url(../assets/papyrus.ttf);
}

@font-face {
    font-family: MadimiOne;
    src: url(../assets/MadimiOne-Regular.ttf);
}

.storyPage {
    display: inline-block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.storyTitle {
    width:100%;
    height: 10vh;
    line-height: 10vh;
    text-align: center;
    padding: 3% 0 3% 0;
    font-size: 2.5vh;
    font-family: papyrus;
    box-sizing: border-box;
}

.storyBook {
    height: 50vh;
    width: 100%;
    box-sizing: border-box;
}

.otherPage {
    display: inline-block;
    width: 5%;
    background-color: #D6B380;
    height: 50vh;
    box-sizing: border-box;
}

.otherPage:hover {
    background-color: #663C00;
    cursor: pointer;
    box-sizing: border-box;
}

.carrot {
    float: left;
    width: 100%;
    height: 50vh;
    padding-left: 33%;
    padding-top: 24vh;
    font-size: 1vw;
    color: #FFF2D7;
    box-sizing: border-box;
}

.currentPage {
    display: inline-block;
    width: 90%;
    background-color: #FCD592;
    height: 100%;
    box-sizing: border-box;
}

.pageText {
    float: left;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: MadimiOne;
    line-height: normal;
    overflow: hidden;
    text-overflow: clip; 
    box-sizing: border-box;
}

.lineText {
    box-sizing: border-box;
}

.storyBookNavigationBlock {
    width: 100%;
    box-sizing: border-box;
}

.storyBookNavigationBlockLeft {
    float: left;
    padding: 3px;
    padding-right: 1vw;
    color: #D6B380;
    box-sizing: border-box;
}

.storyBookNavigationBlockLeft:hover {
    cursor: pointer;
    color: #663C00;
    box-sizing: border-box;
}

.storyBookNavigationBlockRight {
    float: right;
    padding: 3px;
    padding-left: 1vw;
    color: #D6B380;
    box-sizing: border-box;
}

.storyBookNavigationBlockRight:hover {
    cursor: pointer;
    color: #663C00;
    box-sizing: border-box;
}

.storyBookNavigationText {
    display: inline-block;
    font-size: 1vmax;
    line-height: 3vmax;
    box-sizing: border-box;
}

.storyBookNavigationLeft {
    width: 3vmax;
    line-height: 3vmax;
    display: inline-block;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    z-index: 1;
    box-sizing: border-box;
}

.storyBookNavigationRight {
    width: 3vmax;
    line-height: 3vmax;
    display: inline-block;
    box-sizing: border-box;
}

/* Mobile */
@media screen and (max-width: 800px) {
    .pageText {
        font-size: 12px;
    }
}

/* Desktop */
@media screen and (min-width: 801px) {
    .pageText {
        font-size: 18px;
    }
}