@font-face {
    font-family: MadimiOne;
    src: url(../assets/MadimiOne-Regular.ttf);
}

.home-page {
    background-color: var(--darkestPurpleBackgroundPrimary);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 90vh;
    position: relative;
}

.greeting-text, .action-text {
    color: var(--lightTealEffectPrimary);
    font: MadimiOne;
}

.home-action-block {
    position: absolute;
    bottom: 0;
}

/* Mobile */
@media screen and (max-width: 800px) {
    .home-page {
        background-image: url('../../public/superbackground-720-01.png');
    }

    .gap-row {
        min-height: 175px;
    }

    .greeting-text, .action-text {
        font-size: 18px;
    }

    .action-text {
        margin-left: 5vw;
    }
}

/* Desktop */
@media screen and (min-width: 801px) {
    .home-page {
        background-image: url('../../public/superbackground-1920-01.png');
    }

    .gap-row {
        min-height: 250px;
    }

    .greeting-text, .action-text {
        font-size: 25px;
    }

    .action-text {
        margin-left: 25vw;
    }
}