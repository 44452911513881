@font-face {
    font-family: MadimiOne;
    src: url(../assets/MadimiOne-Regular.ttf);
}

.listingBox {
    --listingRightMargin: 2px;
    --listingElementPadding: 5px;
    display: block;
    border: 1px solid black;
    margin: 0 var(--listingRightMargin) 0 2px;
    height: auto;
    position: relative;
}

.listingBackground {
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.listingContent {
    position: relative;
}

.listingBoxTop {
    display: block;
}

.listingBoxBottom {
    display: block;
}

.listingTitle {
    font-family: MadimiOne;
    display: inline-block;
    padding-left: var(--listingElementPadding);
}

.listingCost {
    display: inline-block;
    position: absolute;
    right: 0px;
    margin-right: var(--listingRightMargin);
    padding-right: var(--listingElementPadding);
}

.listingDate {
    display: inline-block;
    padding-left: var(--listingElementPadding);
}

.listingInfo {
    display: inline-block;
    position: absolute;
    right: 0px;
    margin-right: var(--listingRightMargin);
    padding-right: var(--listingElementPadding);
}