.top-nav {
    position: sticky;
    background-color: var(--darkPurpleEffectPrimary);
    box-shadow: 2px 2px 5px var(--midPurpleEffectPrimary);
    z-index: 3;
}

.nav-links {
    padding-left: 10px;
    background-color: var(--lightPurpleEffectPrimary);
    border: 3px solid var(--darkPurpleEffectPrimary);
    box-shadow: 2px 2px 5px var(--midPurpleEffectPrimary);
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.nav-links:hover {
    border-color: var(--lightTealEffectPrimary);
}

.nav-links-mobile {
    width: 100%;
}

.mobile-nav-menu-block {
    color: var(--lightPurpleEffectPrimary);
    cursor: pointer;
}

.mobile-nav-menu-text {
    display: inline-block;
    vertical-align: top;
}

.mobile-nav-menu-icon {
    width: 50px;
    display: inline-block;
}