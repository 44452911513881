@font-face {
    font-family: papyrus;
    src: url(../assets/papyrus.ttf);
}

@font-face {
    font-family: MadimiOne;
    src: url(../assets/MadimiOne-Regular.ttf);
}

.aboutPage {
    background-color: #FFF2D7;
    min-height: 100vh;
    box-sizing: border-box;
}

.aboutSectionTale, .aboutSectionConnect {
    display: inline-block;
    vertical-align: top;
    height: 60vh;
    padding: 0 5% 0 5%;
    width: 50%;
    box-sizing: border-box;
}

.aboutTitle {
    width:100%;
    height: 12vh;
    padding: 3% 0 3% 0;
    font-size: 3.5vmin;
    font-family: "ferryman", sans-serif;
    font-weight: 400;
    font-style: normal;
    overflow: hidden;
    text-overflow: clip; 
    box-sizing: border-box;
}

.missionBlock {
    height: 50vh;
    width: 100%;
    padding-top: 10vh;
    box-sizing: border-box;
}

.missionStatement {
    display: inline-block;
    width: 100%;
    height: auto;
    text-align: center;
    line-height: 2.5;
    font-size: 2vmax;
    font-weight: 400;
    font-family: MadimiOne;
    box-sizing: border-box;
}

.actionStatement {
    display: inline-block;
    width: 100%;
    height: auto;
    text-align: center;
    line-height: 2.5;
    font-size: 1.5vmax;
    box-sizing: border-box;
}

.actionStatement:hover {
    background-color: #ce96ea;
    border-radius: 10px;
    box-sizing: border-box;
}

.followArrow {
    display: inline-block;
    width: 1vw;
    height: auto;
    box-sizing: border-box;
}

.eventIntro {
    height: 24vh;
    width: 100%;
    font-size: 1vmax;
    box-sizing: border-box;
    margin-top: 10vh;
}

.eventIntroTextGroup {
    width: 100%;
    height: auto;
    text-align: center;
    box-sizing: border-box;
}

.eventIntroText {
    width: 100%;
    height: 3vh;
    box-sizing: border-box;
}

.eventDivider {
    width: 90%;
    margin-left: 5%;
    background-color: #090111;
    height: 1vh;
    box-sizing: border-box;
}

.eventDividerBuffer {
    width: 47vw;
    display: inline-block;
    box-sizing: border-box;
}

.eventDividerArrow {
    width: 6vw;
    text-align: center;
    display: inline-block;
    margin-top: 1vh;
    box-sizing: border-box;
}

.eventPage {
    width: 100%;
    min-height: 92vh;
    margin-top: 8vh;
    box-sizing: border-box;
}

.aboutPhoto {
    width: 100%;
    display: inline-block;
}

.aboutTextStyling {
    display: inline-block;
    font-size: 16px;
}

@media screen and (max-width: 800px) {
    .aboutSectionTale, .aboutSectionConnect, .eventIntro {
        width: 100%;
        box-sizing: border-box;
    }
    .aboutSectionConnect {
        height: 50vh;
        box-sizing: border-box;
    }
    .missionStatement {
        font-size: 14px;
        box-sizing: border-box;
    }
    .actionStatement, .eventIntroTextGroup {
        font-size: 2.5vmin;
        box-sizing: border-box;
    }
}