@font-face {
    font-family: papyrus;
    src: url(../assets/papyrus.ttf);
}

@font-face {
    font-family: MadimiOne;
    src: url(../assets/MadimiOne-Regular.ttf);
}

.eventPageBlock {
    width: 100%;
    height: auto;
    display: block;
    box-sizing: border-box;
}

.flyerImageBlock {
    width: 50%;
    padding: 2vh 2vw 2vh 2vw;
    float: left;
    background-color: #FFF2D7;
    box-sizing: border-box;
}

.flyerImage {
    width: auto;
    height: 85vh;
    object-fit: contain;
    float: right;
    box-sizing: border-box;
}

.eventInfoBlock {
    width: 50%;
    padding: 2vh 2vw 2vh 2vw;
    float: left;
    background-color: #FFF2D7;
    box-sizing: border-box;
}

.eventTitle {
    font-size: 6vmin;
    font-family: papyrus;
    font-weight: 600;
    box-sizing: border-box;
}

.eventLocation {
    font-size: 3.5vmin;
    font-family: MadimiOne;
    box-sizing: border-box;
}

.eventDateTime {
    font-size: 2.5vmin;
    font-family: MadimiOne;
    box-sizing: border-box;
}

.eventTimeNote {
    font-size: 1.7vmin;
    font-style: italic;
    box-sizing: border-box;
}

.eventDescription {
    margin-top: 2vh;
    font-size: 2vmin;
    box-sizing: border-box;
}

.eventBulletPoints {
    margin-top: 1vh;
    margin-left: 1rem;
    list-style: circle;
    font-size: 1.7vmin;
    box-sizing: border-box;
}

.eventBulletPoints *{
    margin-top: 1vh;
    box-sizing: border-box;
}

@media screen and (max-width: 800px) {
    .flyerImageBlock, .eventInfoBlock {
        width: 100%;
        box-sizing: border-box;
    }
    .flyerImageBlock {
        padding-top: 0;
        padding-bottom: 0;
        box-sizing: border-box;
    }
    .flyerImage {
        float: none;
        height: 60vh;
        margin: auto;
        box-sizing: border-box;
    }
    .eventTitle {
        font-size: 6vmin;
    }
    .eventLocation {
        font-size: 3.5vmin;
    }
    .eventDateTime {
        font-size: 3vmin;
    }
    .eventTimeNote {
        font-size: 2.25vmin;
    }
    .eventDescription {
        font-size: 2.75vmin;
    }
    .eventBulletPoints {
        font-size: 2.5vmin;
    }
}