body {
  margin: 0;
}

:root {
  /* COLORS */
  --darkestPurpleBackgroundPrimary: #090111;
  --darkPurpleEffectPrimary: #350D5F;
  --lightPurpleEffectPrimary: #CE96EA;
  --lightPurpleEffectSecondary: #FF6AEA;
  --midPurpleEffectPrimary: #92278F;
  --lightTealEffectPrimary: #28BCBC;

  /* Desktop V Mobile */
  --desktopDefaultMargin: 0px;
  --desktopDefaultBorder: 5px;
  --desktopDefaultPadding: 10px;
  --mobileDefaultMargin: 0px;
  --mobileDefaultBorder: 3px;
  --mobileDefaultPadding: 5px;

  /* Default Styles */
  --defaultBorderRadius: 15%;
}