.product-card{
    border: 2px solid var(--darkPurpleEffectPrimary);
    border-radius: 15px;
    box-shadow: var(--midPurpleEffectPrimary) 2px 2px;
}

.product-card-body{
    padding: 10px;
}

.product-image {
    max-width: 100%;
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
    border-bottom: 1px solid var(--midPurpleEffectPrimary);
}

.product-button {
    background-color: var(--lightPurpleEffectPrimary);
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px;
}

.product-button:hover {
    border: 2px solid var(--darkPurpleEffectPrimary);
    cursor: pointer;
}

.checkout-button{
    background-color: var(--lightPurpleEffectPrimary);
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
}

.checkout-button:hover {
    border: 2px solid var(--darkPurpleEffectPrimary);
    cursor: pointer;
}

.checkout-sticky-footer{
    background-color: var(--lightPurpleEffectPrimary);
    padding: 10px 20px 10px 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    .checkout-button {
        float: right;
        background-color: var(--darkPurpleEffectPrimary);
        color: var(--lightTealEffectPrimary);
        border: 2px solid var(--lightPurpleEffectPrimary);
    }
    .checkout-button:hover {
        border: 2px solid var(--midPurpleEffectPrimary);
        cursor: pointer;
    }
}

.padding-row{
    min-height: 10vh;
}