.iconContainer { 
    width: 100%;
}

.iconContainer:hover {
    padding: 0px;
    border-radius: var(--defaultBorderRadius);
}

.iconImage { width: 100%; }

/* Mobile */
@media screen and (max-width: 800px) {
    .iconContainer { padding: var(--mobileDefaultPadding); }
    .iconContainer:hover { border: var(--mobileDefaultBorder) solid var(--lightTealEffectPrimary); }
}

/* Desktop */
@media screen and (min-width: 801px) {
    .iconContainer { padding: var(--desktopDefaultPadding); }
    .iconContainer:hover { border: var(--desktopDefaultBorder) solid var(--lightTealEffectPrimary); }
}