.moreInfoActionButton {
    display: flexbox;
}

/* .moreInfoActionButton:hover {
    
} */

.moreInfoIcon {
    width: 20px;
    height: 20px;
    display: inline;
    margin-right:5px;
    box-sizing: border-box;
}

.moreInfoText {
    display: inline;
    box-sizing: border-box;
}