.icon-group-container {
    padding: 0 20px 0 20px;
}

.icon-container {
    display: inline-block;
    margin: 0 10px 0 10px;
}

/* Mobile */
@media screen and (max-width: 800px) {
    .icon-group-container {
        margin-left: 5vw;
    }
    .icon-container {
        width: 100px;
    }
}

/* Desktop */
@media screen and (min-width: 801px) {
    .icon-group-container {
        margin-left: 25vw;
    }
    .icon-container {
        width: 150px;
    }
}