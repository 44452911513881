/*
  container, row, column, column-1 ... column-12
*/

/*CONTAINER*/
.container, .row, .column {
    box-sizing: border-box;
    display: block;
  }
  /*mobile first media queries*/
  /*@media (min-width: 768px) { .container { width: 750px; } }
  @media (min-width: 992px) { .container { width: 970px; } }
  @media (min-width: 1200px) { .container { width: 1170px; } }*/
  
  /*border-box includes padding and border in declared width & heigth*/
  
  /*clearfix clears floating elements so that rows & columns maintain structure*/
  .row:after { clear:both; }
  .row:before, .row:after {
    content: " ";
    display: table;
  }
  
  /*COLUMNS*/
  [class*="column-"] {
    box-sizing: border-box;
    display: block;
    position: relative;
    float: left;
    margin: .7% .7% .7% .7%;
    /*styles for grid demo*/
    /* min-height: 30px;
    background-color: #7f7f7f;
    border: 3px;
    border-style: solid;
    border-color: #333333;
    box-shadow: 2px 2px 5px #404040;
    border-radius: 5px; */
  }
  .column-1, .column-2, .column-3, .column-4, .column-5, .column-6,
  .column-7, .column-8, .column-9, .column-10, .column-11, .column-12
    { width: 98.6%; }
  
  /*If the mobile device is blazing it, expand columns.*/
  @media only screen and (min-width: 420px) {
    .column-1, .column-2, .column-3, .column-4, .column-5, .column-6
      { width: 48.6%; }
    .column-7, .column-8, .column-9, .column-10, .column-11, .column-12
      { width: 98.6%; }
  }
  
  /*If its a tablet*/
  @media only screen and (min-width: 750px) {
    .column-1, .column-2, .column-3, .column-4, .column-5
      { width: 31.933333%; }
    .column-6
      { width: 48.6%; }
    .column-7, .column-8, .column-9
      { width: 65.266666%; }
    .column-10, .column-11, .column-12
      { width: 98.6%; }
  }
  
  /*If its a computer screen*/
  @media only screen and (min-width: 1020px) {
    /*{100 - [ ( colT / colN ) * marg] / [colT / colN]}
    colT = column total, colN = column #, marg = margin*/
    .column-1{ width: 6.933333333%; }
    .column-2{ width: 15.26666667%; }
    .column-3{ width: 23.6%; }
    .column-4{ width: 31.93333333%; }
    .column-5{ width: 40.26666667%; }
    .column-6{ width: 48.6%; }
    .column-7{ width: 56.93333333%; }
    .column-8{ width: 65.26666667%; }
    .column-9{ width: 73.6%; }
    .column-10{ width: 81.93333333%; }
    .column-11{ width: 90.26666667%; }
    .column-12{ width: 98.6%; }
  }